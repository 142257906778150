import React, { Suspense, lazy } from "react";
import { Flex, CircularProgress } from "@chakra-ui/react";

const Routes = lazy(() => import("./routes"));

const Loader = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100vh" }}
    >
      <CircularProgress isIndeterminate color="#2B4CF2" />
    </Flex>
  );
};

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes />
    </Suspense>
  );
}

export default App;
