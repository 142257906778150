import { extendTheme } from "@chakra-ui/react";

export const customTheme = extendTheme({
  fonts: {
    heading: `Manrope`,
    body: `Manrope`,
  },
  components: {
    Input: {
      variants: {
        primary: {
          field: {
            bgColor: "#fff",
            borderRadius: "4px",
            color: "#000",
            _placeholder: {
              color: "gray",
            },
          },
        },
        alternate: {
          field: {
            bgColor: "#F2F2F2",
            border: "1px solid #E0E0E0",
            borderRadius: "5px",
            color: "#000",
            _placeholder: {
              color: "#333333",
              fontSize: "13px",
            },
          },
        },
        secondary: {
          field: {
            bgColor: "#F2F2F2",
            borderRadius: "4px",
            color: "#000",
            _placeholder: {
              color: "#BDBDBD",
            },
          },
        },
      },
      defaultProps: {
        variant: "primary",
      },
    },
    Select: {
      variants: {
        primary: {
          field: {
            bgColor: "#F1EBF9",
            borderRadius: "10px",
            cursor: "pointer",
          },
        },
        secondary: {
          field: {
            rounded: "full",
            borderRadius: "10px",
            cursor: "pointer",
            bg: "#fafafa",
            border: "1px solid #E0E0E0",
          },
        },
      },
      defaultProps: {
        variant: "primary",
      },
    },
    Textarea: {
      variants: {
        primary: {
          bg: "#f2f2f2",
          color: "#000",
          borderRadius: "10px",
          _placeholder: {
            color: "#BDBDBD",
          },
        },
        secondary: {
          bg: "#fff",
          color: "#000",
          border: "1px solid gray",
          _placeholder: {
            color: "#BDBDBD",
          },
        },
      },
      defaultProps: {
        variant: "primary",
      },
    },

    Button: {
      variants: {
        primary: {
          border: "none",
          bgColor: "#2B4CF2",
          borderRadius: "4px",
          color: "#fff",
          transition: ".3s ease-in-out",
          fontSize: "14px",
          fontWeight: "500",
          py: 6,
          px: 6,
          _hover: {
            opacity: 0.9,
          },
          _disabled: {
            opacity: 0.9,
          },
        },
      },
      defaultProps: {
        variant: "primary",
      },
    },
  },

  colors: {
    red: "#EE383A",
  },
});
